import { useState } from 'react';
import { countBy, identity, isEmpty, isNil, keys, pickBy } from 'ramda';
import { debounce } from 'utils';
import { useExistingTags } from './queryHooks';

function buildTagsArray(tagObject) {
  return keys(tagObject)?.map((key) => ({
    value: key,
    count: tagObject[key],
  }));
}

export function useTimelineTags() {
  const [query, setQuery] = useState();
  const matches = (_, key) =>
    key?.toLowerCase?.()?.includes(query?.toLowerCase?.());

  const { data: tags, isFetched, isFetching, refetch } = useExistingTags();
  const countedTags = isFetched && countBy(identity)(tags);

  const needsFiltering = !isNil(query) && !isEmpty(query);

  const tagObject = needsFiltering ? pickBy(matches, countedTags) : countedTags;

  return {
    isFetched,
    isFetching,
    refetch,
    inSearchEmptyState:
      !isFetching && isFetched && needsFiltering && isEmpty(tagObject),
    tags: buildTagsArray(tagObject),
    setQuery: debounce((value) => setQuery(value), 500),
  };
}
