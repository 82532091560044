function SquareButton({ inverse, className, ...props }) {
  const classes = [
    'w-7 h-7 p-1',
    `${inverse ? 'text-white' : 'text-black'} opacity-75 hover:opacity-90`,
    `${inverse ? 'bg-black' : 'bg-white'} bg-opacity-40 hover:bg-opacity-60`,
    'transform hover:scale-105',
    'focus:outline-none select-none',
  ];

  if (className) classes.push(className);

  return <button className={classes.join(' ')} {...props} />;
}

export default SquareButton;
