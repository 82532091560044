import { useMemo } from 'react';
import { MANUAL_INPUT_TYPE } from '../layout/modules/ManualInputs/const';
import { determineRangeValue } from '../utils';

export function useRatioField(data) {
  const ratioField = useMemo(
    () =>
      data?.storyblock?.fields?.find(
        (field) => field?.inputType === MANUAL_INPUT_TYPE.RATIO.toLowerCase()
      ),
    [data?.storyblock?.fields]
  );
  return { ratioField };
}

export function useRatioRangeValue(data) {
  const { ratioField } = useRatioField(data);

  const ratioFieldRanges = ratioField?.rangeValue ?? [];
  const numeratorValue = data?.observations?.[0]?.value ?? data?.value?.[0];
  const denominatorValue = data?.observations?.[1]?.value ?? data?.value?.[1];

  const ratioRangeValue = determineRangeValue(
    numeratorValue,
    denominatorValue,
    ratioFieldRanges
  );

  return ratioRangeValue;
}

const MIN_ITEMS_REQUIRED = 2;

export const useCombinedPanelItems = (data, separator, storyblock) => {
  return useMemo(() => {
    if (!data || data.length < MIN_ITEMS_REQUIRED) return [];

    const [numerator, denominator] = data;

    return numerator.items
      .map((numeratorItem) => {
        const matchingItem = denominator.items.find(
          (denominatorItem) => denominatorItem.panelId === numeratorItem.panelId
        );
        if (matchingItem)
          return {
            ...matchingItem,
            separator,
            storyblock,
            value: [Number(numeratorItem?.value), Number(matchingItem?.value)],
          };
        return null;
      })
      .filter(Boolean);
  }, [data, separator, storyblock]);
};
