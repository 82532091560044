import { evaluate } from 'mathjs';

export function toFixedDecimalIfNumber(value, numbersAfterDecimal = 2) {
  if (['undefined', 'object', 'boolean'].includes(typeof value)) {
    return Number(0).toFixed(numbersAfterDecimal);
  }
  if (isNaN(value)) {
    return value;
  }

  const numberValue = Number(value);

  if (!isFinite(numberValue)) {
    return null;
  }

  const formattedNumber = numberValue.toLocaleString('en-US', {
    minimumFractionDigits: numbersAfterDecimal,
    maximumFractionDigits: numbersAfterDecimal,
  });

  return formattedNumber;
}

export function digitsAfterDecimal(num) {
  if (Number.isInteger(num)) {
    return 0;
  }

  const arr = num?.toString().split('.');

  return arr?.[1]?.length ?? 0;
}

export const arbitraryUnitRegex = new RegExp(/\[iu\]/i);

export function determineRangeValue(
  numeratorValue,
  denominatorValue,
  rangeValue
) {
  for (let range of rangeValue) {
    if (Boolean(numeratorValue) && Boolean(denominatorValue)) {
      const parsedCondition = range.condition
        .replace(/{{ numerator.value }}/g, numeratorValue)
        .replace(/{{ denominator.value }}/g, denominatorValue);

      const [numerator, denominator] = parsedCondition.split(/\|\||&&/);

      const numResult = evaluate(numerator);
      const denomResult = evaluate(denominator);

      const result = parsedCondition.includes('||')
        ? numResult || denomResult
        : numResult && denomResult;

      if (Boolean(result)) {
        return range.value;
      }
    }
  }
}
