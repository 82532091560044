import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { isNil } from 'ramda';

import { parseCtaAction } from 'landing/helpers/helpers';

import { useAuth } from 'Auth';
import { PHR_ROUTES } from 'my-phr/const';

function useParseMyPhrIntro(data) {
  const { authenticated } = useAuth();
  const { t } = useTranslation();

  return useMemo(() => {
    if (isNil(data)) {
      return {
        slides: [],
        videos: {
          full: null,
          background: null,
        },
        personas: [],
        features: [],
        pricing: [],
      };
    }

    const parsedData = {};

    const changeAuthCtaText = (curr) =>
      authenticated ? t('my-phr.intro.button') : curr?.['cta-text'];

    const changeAuthCtaAction = (curr) =>
      authenticated
        ? { ctaInternal: PHR_ROUTES.SETUP_PROFILE }
        : parseCtaAction(curr);

    // PUBLIC / DEFAULT Public PAGE CONTENT

    //Hero Slideshow slides
    parsedData.slides =
      data?.slideshow?.reduce((prev, curr) => {
        const parsedCurr = {
          ...curr,
          imageTitle: curr?.['image-title'],
          ctaText: changeAuthCtaText(curr),
          ctaProps: changeAuthCtaAction(curr),
        };

        return [...prev, parsedCurr];
      }, []) ?? [];

    //Intro section videos
    parsedData.videos = data?.video?.reduce((prev, curr) => {
      const parsedCurr = {
        ...curr,
        ctaText: changeAuthCtaText(curr),
        ctaProps: changeAuthCtaAction(curr),
      };
      return { ...prev, [curr?.version]: parsedCurr };
    }, {}) ?? {
      full: null,
      background: null,
    };

    //Features section
    parsedData.features =
      data?.features?.reduce((prev, curr) => {
        const parsedCurr = {
          ...curr,
          ctaText: !authenticated && curr?.['cta-text'],
          ctaProps: !authenticated && parseCtaAction(curr),
        };
        return [...prev, parsedCurr];
      }, []) ?? [];

    // Personas
    parsedData.personas =
      data?.personas?.reduce((prev, curr) => {
        const parsedCurr = {
          ...curr,
          imageTitle: curr?.['image-title'],
          subTitle: curr?.['sub-title'],
          ctaText: curr?.['cta-text'],
          video: curr?.video,
        };
        return [...prev, parsedCurr];
      }, []) ?? [];

    // Pricing
    parsedData.pricing =
      data?.pricing?.reduce((prev, curr) => {
        const parsedCurr = {
          ...curr,
          imageTitle: curr?.['image-title'],
          subTitle: curr?.['sub-title'],
          ctaText: changeAuthCtaText(curr),
          ctaProps: changeAuthCtaAction(curr),
          video: curr?.video,
        };

        return { ...prev, ...parsedCurr };
      }, {}) ?? {};

    return parsedData;
  }, [data, authenticated, t]);
}

export default useParseMyPhrIntro;
