import { RESULT_STATUS } from 'my-phr/const';
import { colors } from 'tailwindTheme';

//copied from range sliders with added opacity
export const RESULT_STATUS_COLOR = {
  [RESULT_STATUS.NORMAL]: '#20CC9B',
  [RESULT_STATUS.BORDERLINE]: '#ffcc00',
  [RESULT_STATUS.ABNORMAL]: '#d83030',
  [RESULT_STATUS.NEGATIVE]: '#4b7793',
  [RESULT_STATUS.POSITIVE]: '#fe7d5d',
  [RESULT_STATUS.INDETERMINATE]: colors.smd.gray.DEFAULT,
};
