import { useMemo } from 'react';
import { usePhrUnitValuePreference } from 'my-phr/hooks';
import { getRangesResultStatusColor } from 'my-phr/components/result/utils';

export default function useChartDataWithFeedbackColor(data, topic) {
  const { getValue, getRawUnit } = usePhrUnitValuePreference();
  return useMemo(
    () =>
      data.map((x) => {
        // getting the range color for the value for each point in the chart
        const color = getRangesResultStatusColor({
          topics: topic ? [topic] : [],
          rawUnit: getRawUnit(x),
          value: getValue(x),
          molecularWeight: x?.storyblock?.molecularWeight,
        });

        return {
          ...x,
          effectiveTs: new Date(x.effectiveTs).getTime(),
          value: x.value,
          topics: topic ? [topic] : [],
          color,
        };
      }),
    [data, topic, getValue, getRawUnit]
  );
}

export function useBarChartDataWithFeedbackColor(data, numerator, denominator) {
  return useMemo(
    () =>
      data?.map((entry) => {
        const numeratorColor = getRangesResultStatusColor({
          topics: numerator?.ranges,
          rawUnit: null,
          value: entry?.value?.[0],
          molecularWeight: entry?.storyblock?.molecularWeight,
        });

        const denominatorColor = getRangesResultStatusColor({
          topics: denominator?.ranges,
          rawUnit: null,
          value: entry?.value?.[1],
          molecularWeight: entry?.storyblock?.molecularWeight,
        });

        return {
          ...entry,
          effectiveTs: new Date(entry.effectiveTs).getTime(),
          value: entry.value,
          numeratorColor,
          denominatorColor,
        };
      }),
    [data, numerator?.ranges, denominator?.ranges]
  );
}
