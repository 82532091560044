import {
  __,
  assocPath,
  defaultTo,
  mapObjIndexed,
  modifyPath,
  pathOr,
} from 'ramda';
import { getDistributionConfig } from 'distribution';

export function getDistributionBannersByType(entity, idField, id) {
  const config = getDistributionConfig([entity, 'banners', 'configurations']);

  if (!config) return [];

  for (const entry of config) {
    if (entry[idField].includes(id)) {
      return entry.banners;
    }
  }
  return [];
}

export function getDistributionLogos(journalKey) {
  return getDistributionConfig(['journals', 'logos', journalKey]);
}

export function getDistributionBanners(journalKey) {
  return getDistributionBannersByType('journals', 'journalIds', journalKey);
}

export function getDistributionJourneyBanners(journeyId) {
  return getDistributionBannersByType('journeys', 'journeyIds', journeyId);
}

export function getDistributionStoryBanners(storyId) {
  return getDistributionBannersByType('stories', 'storyIds', storyId);
}

export function applyDistributionConfig(journal) {
  const distributionLogos = getDistributionLogos(journal?.journalKey);
  const distributionBanners = getDistributionBanners(journal?.journalKey);

  const journalWithLogos = modifyPath(
    ['preamble', 'logos'],
    defaultTo(__, distributionLogos),
    journal
  );

  return assocPath(
    ['preamble', 'banners'],
    pathOr(distributionBanners, ['preamble', 'banners'], journalWithLogos),
    journalWithLogos
  );
}

export function applyDistributionConfigToJourney(journey) {
  return mapObjIndexed((singleJourney, journeyId) => {
    const distributionBanners = getDistributionJourneyBanners(journeyId);

    return assocPath(['banners'], distributionBanners, singleJourney);
  }, journey);
}
