import { useTranslation } from 'react-i18next';

export function useAddRequiredSuffix(label, required) {
  const { t } = useTranslation();

  return (
    <div className="space-x-2">
      <span>{label}</span>
      <span className="font-normal italic text-smd-gray">
        {`${required ? '' : t('my-phr.manual-inputs.labels.optional')}`}
      </span>
    </div>
  );
}
